<template>
  <div class="page">
    <div class="container">
      <!--门票预定-->
      <div >
        <img class="full-img" mode="widthFix" :src="subjectInfo.banner">
        <div class="mpyd-list">
          <div class="top">
            <div class="title">{{title}}</div>
          </div>
          <!--TODO: scrolldiv-->
          <!--单日票-->
		  <div v-if="ticketList.length>0">
			<div
			    class="item"
			    v-for="(ticket, idx) in ticketList"
			    :key="ticket.tl"
			>
			  <div class="sb flexcenter" @click="showDetail(ticket.id)">
			    <div class="title">{{ticket.goods_name}}</div>
			    <div>
			      <span class="price"><span class="fz24">￥</span>{{ticket.price}}</span>
			      <span class="gray">起</span>
			    </div>
			  </div>
			  <div class="sb flexcenter">
			    <div>适用人群:{{ticket.person_type_span}}</div>
			  </div>
			  <div class="sb flexcenter">
			    <div>
			      <span v-if="ticket.max_qty>0">限购 {{ticket.max_qty}} 张</span>
			      <span v-if="ticket.max_qty>0" class="line m12">|</span>
			      <span>已售{{ticket.v_sale_number}}+</span>
			      <span class="line m12">|</span>
			      <span class="blue" @click="showDetail(ticket.id)">购买须知</span>
			    </div>
			    <div class="yd" @click="preOrder(ticket)">预订</div>
			  </div>
			</div>
          </div>
		</div>
      
        <div class="mpyd-list" v-if="ticketCardList.length>0">
          <!--优速通-->
          <div class="top">
            <div class="title">优速通</div>
          </div>
          <div
              class="item"
              v-for="(card, idx) in cardCardList"
              :key="ticket.tcl"
          >
            <div class="sb flexcenter" @click="showDetail(card.id)">
              <div class="title">{{card.goods_name}}</div>
              <div>
                <span class="price"><span class="fz24">￥</span>{{card.price}}</span>
                <span class="gray">起</span>
              </div>
            </div>
            <div class="sb flexcenter">
              <div>
                <span v-if="card.max_qty>0">限购 {{card.max_qty}} 张</span>
                <span v-if="card.max_qty>0" class="line m12">|</span>
                <span>已售{{card.v_sale_number}}+</span>
                <span class="line m12">|</span>
                <span class="blue" @click="showDetail(card.id)">购买须知</span>
              </div>
              <div class="yd"  @click="preOrder(card)">预订</div>
            </div>
          </div>
        </div>
      
        <div class="mpyd-list" v-if="ticketPlus.length > 0">
          <!--优速通-->
          <div class="top">
            <div class="title">门票套餐</div>
          </div>
          <div
              class="item"
              v-for="(card, idx) in ticketPlus"
            >
            <div class="sb flexcenter" @click="showDetail(card.id)">
              <div class="title">{{card.goods_name}}</div>
          
            </div>
            <div class="sb flexcenter">
              <div>
                <span class="price"><span class="fz24">￥</span>{{card.price}}</span>
                <span class="gray">起</span>
              </div>
            </div>
            <div class="sb flexcenter">
              <div>
                <span v-if="card.max_qty>0">限购 {{card.max_qty}} 张</span>
                <span v-if="card.max_qty>0" class="line m12">|</span>
                <span>已售{{card.v_sale_number}}+</span>
                <span class="line m12">|</span>
                <span class="blue" @click="showDetail(card.id)">套餐详情</span>
              </div>
              <div class="yd" @click="preOrder(card)">预订</div>
            </div>
          </div>
        </div>
      
        <div class="mpyd-list" v-if="ticketGroup.length > 0">
          <!--优速通-->
          <div class="top">
            <div class="title">1.5日门票</div>
          </div>
          <div
              class="item"
              v-for="(card, idx) in ticketGroup"
          >
            <div class="sb flexcenter" @click="showDetail(card.id)">
              <div class="title">{{card.goods_name}}</div>
          
            </div>
            <div class="sb flexcenter">
              <div>
                <span class="price"><span class="fz24">￥</span>{{card.price}}</span>
                <span class="gray">起</span>
              </div>
            </div>
            <div class="sb flexcenter">
              <div>
                <span v-if="card.max_qty>0">限购 {{card.max_qty}} 张</span>
                <span v-if="card.max_qty>0" class="line m12">|</span>
                <span>已售{{card.v_sale_number}}+</span>
                <span class="line m12">|</span>
                <span class="blue" @click="showDetail(card.id)">套餐详情</span>
              </div>
              <div class="yd" @click="preOrder(card)">预订</div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'subject',
    data() {
      return {
        tab: 0,
        page: 1,
        totalPage: 0,
        loading:false,
        isopen:0, //是否正式开售
        wait_msg:'',//
        filter:{}, //搜索条件
		title:'单日门票',
		tids:'',
        subjectInfo:{}, //景区数据
        ticketList:[],  //一日游卡
        ticketCardList:[],//年卡等
        ticketPlus:[], //票加产品
        ticketGroup:[], //跟团游
      }
    },
    components: {
    },
    created() {
      let sid = this.$route.query.sid;
	  let tit = this.$route.query.title;
	  let tids = this.$route.query.tid;
      if(typeof sid=='undefined'||sid<1)
      {
        this.$toast('数据不存在')

        setTimeout(() => {
			this.$router.go(-1)
        }, 1000);
      }
	  
	  if(typeof tit!='undefined'||tit!='')
	  {
	    this.title = tit;
	  }
	  
	  if(typeof tids!='undefined'||tids!='')
	  {
	    this.tids = tids;
	  }
	  else
	  {
		  tids = '';
	  }
	  
	  console.log(sid);
      this.getTicketList(sid,tids,1,true);
    },
    methods: {
      //获取一日游门票
      getTicketList(subjectId,tids='',pageNo, override){
        let that = this;
        this.post(this.API.tourSubjectIndex,{ page:pageNo,id:subjectId,tids:tids})
			.then(response => {
				
			  let subjectInfo = {
				id:response.data.subject.id,
				subject_name : response.data.subject.subject_name,
				banner : response.data.subject.banner,
				bg_color : response.data.subject.bg_color,
			  }
			  

			  let ticketList = response.data.subject.tickets.data;
			  let ticketCardList = response.data.subject.other_tickets.data;
			  let ticketPlus = response.data.subject.tickets_plus.data;
			  let ticketGroup = response.data.subject.groups.data;
			  
			  that.page	= response.data.subject.tickets.current_page     //当前的页号
			  that.totalPage	= response.data.subject.tickets.last_page  //总页数
			  that.isopen	=response.data.isopen//是否开启
			  that.wait_msg	=response.data.wait_msg
			  that.subjectInfo=subjectInfo
			  that.ticketList= override ? ticketList : that.data.subject.tickets.concat(ticketList)
			  that.ticketCardList=override ? ticketCardList : that.data.subject.other_tickets.concat(ticketCardList)
			  that.ticketPlus=override ? ticketPlus : that.data.subject.tickets_plus.concat(ticketPlus)
			  that.ticketGroup=override ? ticketGroup : that.data.subject.groups.concat(ticketGroup)
			  
			  console.log("=====");
			  console.log(this.subjectInfo);
			})
      },
      //点击预订按钮
      preOrder({id, isopen}){
        if(isopen=='1') {
          this.$router.push({
            name: 'tourCheckout',
            query: {
              tid: id
            }
          })
        } else {
          this.$toast(this.wait_msg)
        }
      },
      showDetail(tid){
        this.$router.push({
          name: 'tourDetail',
          query: {
            tid,
          }
        })
      },
      goBackPage:function() {
        this.$router.go(-2)
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    background-color: #0658E0;
    min-height: 100%;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }

  .hqimg {
    display: block;
    height: 218px;
  }
  .ztgy-list {
    margin-top: -68px;
    padding-bottom: 204px;
  }
  .ztgy-list .item {
    justify-content: space-between;
    margin: 0 24px;
    margin-bottom: 32px;
  }
  .ztgy-list .text-box {
    width: 278px;
    color: #FFFFFF;
  }
  .ztgy-list .text-box .title {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: bold;
  }
  .ztgy-list .text-box .desc {
    font-size: 20px;
    line-height: 30px;
  }
  .ztgy-list .text-box .eng {
    letter-spacing: -4px;
  }
  .ztgy-list-bottom {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
  }
  .ztgy-list .lb1 {
    font-size: 28px;
  }
  .ztgy-list .lb2 {
    font-size: 36px;
  }

  .jdms-list {
    padding: 0 30px;
    padding-bottom: 204px;
    color: #FFFFFF;
  }
  .jdms-list .item {
    margin-bottom: 32px;
  }
  .jdms-list .item .title {
    font-size: 36px;
    font-weight: bold;
  }
  .jdms-list .item .p20 {
    padding: 20px 0;
  }
  .jdms-list .item .desc {
    font-size: 20px;
    text-indent: 50px;
    line-height: 1.7;
  }

  .mpyd-list {
    position: relative;
    box-sizing: border-box;
    margin: 26px 30px;
    margin-bottom: 40px;
    padding: 152px 20px 10px;
    border-radius: 20px;
    background-color: #FFFFFF;
  }
  .mpyd-list .top {
    margin:-130px auto 20px;
    width: 228px;
  }
  .mpyd-list .top .title {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #0658E0;
  }
  .mpyd-list .item {
    margin-bottom: 24px;
    padding: 24px;
    /* height: 220px; */
    background-color: #EEEEEE;
    font-size: 24px;
  }
  .mpyd-list .item .sb{
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .mpyd-list .item .title{
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }
  .mpyd-list .item .gray{
    color: #777777;
  }
  .mpyd-list .item .huadiao{
    text-decoration: line-through;
  }
  .mpyd-list .item .price{
    font-size: 32px;
    font-weight: bold;
    color: #FF3B33;
  }
  .mpyd-list .item .fz24{
    font-size: 24px;
  }
  .mpyd-list .item .blue{
    color: #0658E0;
  }
  .mpyd-list .item .yj{
    color: #F84F41;
  }
  .mpyd-list .item .gf{
    color: #191919;
  }
  .mpyd-list .item .m12{
    margin: 0 12px;
  }
  .mpyd-list .item .line {
    color: #C7C7C7;
  }
  .mpyd-list .item .yd{
    width: 130px;
    height: 52px;
    line-height: 52px;
    background-color: #F84F41;
    text-align: center;
    border-radius: 26px;
    color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
  }
  .mpyd-bottom {
    margin-bottom: 200px;
    text-align: center;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 140px;
    box-sizing: border-box;
    padding: 0 83px;
    background: #FFFFFF;
  }
  .footer .border {
    margin-top: 19px;
    width: 2px;
    height: 88px;
    background-color: #DCDDDD;
  }
  .footer .item {
    margin-top: -44px;
    width: 124px;
    font-size: 24px;
    color: #777777;
    font-weight: bold;
    text-align: center;
  }
  .footer .desc {
    margin-top: 8px;
  }
  .footer .active .desc {
    color: #0658E0;
  }

</style>
